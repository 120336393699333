import {
  CreateNewLpInOnboardingForm,
  isLPFromActiveFundsAndLpsQuery,
} from 'business/fund-manager/onboarding-subscription/services/types';
import { ActiveFundsAndLpsQuery } from 'generated/graphql';
import { getPersonFullName } from 'technical/get-person-full-name/get-person-full-name';
import { OnboardingSubscriptionLpFormPart } from 'ui/onboarding-subscription-lp-form-part';
import { Option } from 'ui/types';

interface LpFormPartProps {
  index: number;
  lp:
    | CreateNewLpInOnboardingForm
    | ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number];
  shares: Option[];
}
export const LpFormPart = ({ index, lp, shares }: LpFormPartProps) => {
  // TODO PPDS - remove console.log quand le pb d'Aline (albarest) est résolu
  console.log('in LpFormPart, lp :', lp);
  console.log('in LpFormPart, shares :', shares);
  console.log(
    'in LpFormPart, isLPFromActiveFundsAndLpsQuery(lp) :',
    isLPFromActiveFundsAndLpsQuery(lp),
  );
  if (isLPFromActiveFundsAndLpsQuery(lp)) {
    return (
      <OnboardingSubscriptionLpFormPart
        type="existing"
        key={lp.subscribingEntityName}
        lpName={lp.subscribingEntityName ?? ''}
        contactFullName={
          lp?.lpContacts?.[0]?.contactView
            ? getPersonFullName(lp?.lpContacts?.[0]?.contactView)
            : ''
        }
        contactEmail={lp?.lpContacts?.[0]?.contactView?.email ?? ''}
        shares={shares}
        name={`subscriptions.${index}`}
        taxOption={lp.taxOption ?? false}
      />
    );
  }
  return (
    <OnboardingSubscriptionLpFormPart
      type="new"
      key={lp.subscribingEntityName}
      lpName={lp.subscribingEntityName ?? ''}
      contactFullName={getPersonFullName(lp)}
      contactEmail={lp.email}
      shares={shares}
      name={`subscriptions.${index}`}
      taxOption={false}
    />
  );
};
