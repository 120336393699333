import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'translations/hook';

import { CreateNewLpInOnboardingFormModal } from 'business/fund-manager/onboarding-subscription/components/create-new-lp-in-onboarding-form-modal/create-new-lp-in-onboarding-form-modal';
import { FundAndLpSelection } from 'business/fund-manager/onboarding-subscription/components/fund-and-lp-selection';
import { OnboardingSubscriptionsCreationForm } from 'business/fund-manager/onboarding-subscription/components/onboarding-subscriptions-creation-form';
import {
  CreateNewLpInOnboardingForm,
  CreateOnboardingSubscription,
} from 'business/fund-manager/onboarding-subscription/services/types';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useActiveFundsAndLpsQuery } from 'generated/graphql';
import { isDefined } from 'technical/is-defined';
import { CustomModal } from 'ui/custom-modal';
import { ErrorLabel } from 'ui/error-label';
import { QueryStateDisplay } from 'ui/query-state-display';
import { Option } from 'ui/types';

export const OnboardingSubscriptionsCreationPage = () => {
  const { data, loading, error } = useActiveFundsAndLpsQuery({
    fetchPolicy: 'network-only',
  });
  const { t } = useTranslation();
  const [fundAndLps, setFundAndLps] = useState<
    CreateOnboardingSubscription | undefined
  >();
  const [addNewLpModal, setAddNewLpModal] = useState(false);

  const [lpsToCreate, setLpsToCreate] = useState<CreateNewLpInOnboardingForm[]>(
    [],
  );
  // TODO PPDS - remove console.log quand le pb d'Aline (albarest) est résolu
  console.log(
    'PAGE in OnboardingSubscriptionsCreationPage, lpsToCreate :',
    lpsToCreate,
  );
  const onSubmit = async (formData: CreateNewLpInOnboardingForm) => {
    console.log(
      'onSubmit in OnboardingSubscriptionsCreationPage, ONSUBMIT formData :',
      formData,
    );
    setLpsToCreate([...lpsToCreate, formData]);
    setAddNewLpModal(false);
    enqueueSnackbar(t('successMessage.newUserToInviteAdded'), {
      variant: VariantTypeEnum.SUCCESS,
    });
  };

  if (
    loading ||
    error ||
    !data ||
    !data.activeFundsAndLps.lps ||
    !data.activeFundsAndLps.funds
  ) {
    return <QueryStateDisplay error={error} loading={loading} />;
  }
  console.log(
    'PAGE in OnboardingSubscriptionsCreationPage, data.activeFundsAndLps :',
    data.activeFundsAndLps,
  );
  const lpExistingNames = [
    ...(data.activeFundsAndLps?.lps ?? []),
    ...lpsToCreate,
  ]
    .map((value) => value.subscribingEntityName)
    .filter(isDefined);

  console.log(
    'PAGE in OnboardingSubscriptionsCreationPage, lpExistingNames :',
    lpExistingNames,
  );
  if (!fundAndLps) {
    return (
      <>
        <FundAndLpSelection
          onSubmit={setFundAndLps}
          onAdd={() => setAddNewLpModal(true)}
          funds={data.activeFundsAndLps.funds}
          lps={[
            ...lpsToCreate.map(({ subscribingEntityName }) => ({
              id: '',
              managementCompanyId: '',
              subscribingEntityName: subscribingEntityName ?? '',
            })),
            ...data.activeFundsAndLps.lps,
          ]}
        />
        {addNewLpModal ? (
          <CustomModal
            open={addNewLpModal}
            handleClose={() => setAddNewLpModal(false)}
            component={
              <CreateNewLpInOnboardingFormModal
                onClose={() => setAddNewLpModal(false)}
                onSubmit={onSubmit}
                lpForbiddenNames={lpExistingNames}
              />
            }
          />
        ) : null}
      </>
    );
  }

  const fund = data.activeFundsAndLps.funds.find(
    (f) => f.id === fundAndLps.fundId,
  );

  if (!fund) {
    // normally we will never be here but typescript need it and I have clearly no simple idea to avoid it :/
    return <ErrorLabel label="errors.fundNotFound" />;
  }

  const shares: Option[] = fund.shares.map((share) => ({
    id: share.id,
    value: share.name,
  }));
  const lps = [...lpsToCreate, ...data.activeFundsAndLps.lps].filter((lp) =>
    fundAndLps.subscribingEntityNames.includes(lp?.subscribingEntityName ?? ''),
  );

  console.log('PAGE in OnboardingSubscriptionsCreationPage, lps :', lps);
  console.log(
    'PAGE in OnboardingSubscriptionsCreationPage, fundAndLps :',
    fundAndLps,
  );

  return (
    <OnboardingSubscriptionsCreationForm
      fund={fund}
      lps={lps}
      shares={shares}
      onCancel={() => setFundAndLps(undefined)}
      defaultValues={{
        subscriptions: lps.map((lp) => ({
          subscribingEntityName: lp.subscribingEntityName ?? '',
          shares: [],
        })),
      }}
    />
  );
};
